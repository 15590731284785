var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getPopup } from "@/shared/ui/popup";
var Webform = /** @class */ (function () {
    function Webform(el) {
        this.el = el;
        this.setupListeners();
    }
    Webform.prototype.setupListeners = function () {
        this.el.addEventListener("submit", this.onSubmit);
    };
    Webform.prototype.onSubmit = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var formEl, formData, action, response, respJson, popup, popupContentEl, notification;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        formEl = e.target;
                        formData = new FormData(formEl);
                        action = formEl.action + ".json?redirect_disallow=1";
                        return [4 /*yield*/, fetch(action, {
                                method: "POST",
                                body: formData,
                            })];
                    case 1:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        respJson = _b.sent();
                        popup = getPopup();
                        popupContentEl = popup.getContentElement();
                        if (popupContentEl) {
                            document.dispatchEvent(new Event("popup:closed"));
                            notification = {
                                title: "Спасибо за обращение!",
                                content: "<p>В ближайшее время мы с вами свяжемся.</p>",
                            };
                            if (respJson === null || respJson === void 0 ? void 0 : respJson.error) {
                                notification.title = "Произошла ошибка!";
                                notification.content = "<p>".concat((_a = respJson.error) === null || _a === void 0 ? void 0 : _a.message, "</p>");
                            }
                            popup.open("<div class=\"popup-notification\"><div class=\"popup-notification__title\">".concat(notification.title, "</div><div class=\"popup-notification__content\">").concat(notification.content, "</div></div>"));
                            popup.lastRenderedTplId = "";
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Webform.prototype.destroy = function () {
        this.el.removeEventListener("submit", this.onSubmit);
    };
    return Webform;
}());
export function webformInit() {
    var forms = document.querySelectorAll("form.webform");
    if (forms === null || forms === void 0 ? void 0 : forms.length) {
        forms.forEach(function (formEl) {
            var webform = new Webform(formEl);
        });
    }
    var popup = getPopup();
    var popupContentEl = popup.getContentElement();
    if (popupContentEl) {
        document.addEventListener("popup:opened", function (e) {
            var popupWebformEl = popupContentEl.querySelector("form.webform");
            if (popupWebformEl) {
                var popupWebform_1 = new Webform(popupWebformEl);
                document.addEventListener("popup:closed", function (e) {
                    popupWebform_1.destroy();
                });
            }
        });
    }
}
