import "@/widgets/layouts/header";
import "@/widgets/layouts/footer";
import { headerNavInit } from "@/widgets/nav/headerNav";
import { sidebarInit } from "@/widgets/layouts/sidebar";
import { sidebarNavInit } from "@/widgets/nav/sidebarNav";
import { sidebarFormInit } from "@/features/sidebarForm";
import { getPopup } from "@/shared/ui/popup";
import { blockAccessoriesInit } from "./blocks/accessories";
import { blockPopularsInit } from "./blocks/populars";
import { blockClientsInit } from "./blocks/clients";
import { blockReviewsInit } from "./blocks/reviews";
export function homePageInit() {
    headerNavInit();
    sidebarInit();
    sidebarNavInit();
    sidebarFormInit();
    var popup = getPopup();
    blockAccessoriesInit();
    blockPopularsInit();
    blockClientsInit();
    blockReviewsInit();
}
