export function formSearchInit() {
    var search = document.getElementById("search");
    var searchButton = search === null || search === void 0 ? void 0 : search.querySelector(".search__btn");
    var searchField = search === null || search === void 0 ? void 0 : search.querySelector(".search__field");
    if (search && searchButton && searchField) {
        searchButton.addEventListener("click", function () {
            if (search.classList.contains("open") &&
                searchField.value.trim() !== "") {
                search.submit();
            }
            else {
                toggleSearch();
            }
        });
    }
    function toggleSearch() {
        search.classList.toggle("open");
        if (search.classList.contains("open")) {
            searchField.focus();
        }
        onClickClose(search);
    }
    function onClickClose(elem) {
        function outsideClickListener(event) {
            if (!elem.contains(event.target)) {
                elem.classList.remove("open");
                document.removeEventListener("click", outsideClickListener);
            }
        }
        document.addEventListener("click", outsideClickListener);
    }
}
