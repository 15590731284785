import "swiper/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Lazy } from "swiper";
export function blockReviewsInit() {
    if (document.querySelector(".reviews__items")) {
        new Swiper(".reviews__items", {
            modules: [Navigation, Pagination, Lazy],
            speed: 500,
            slidesPerView: 1,
            loop: true,
            lazy: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".reviews .slider-button_next",
                prevEl: ".reviews .slider-button_prev",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 32,
                },
                950: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
                1150: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
            },
        });
    }
}
