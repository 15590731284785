import "swiper/swiper-bundle.min.css";
import Swiper, { Navigation, Grid, Lazy } from "swiper";
export function blockClientsInit() {
    if (document.querySelector(".clients__slider")) {
        var swiper = new Swiper(".clients__slider", {
            modules: [Navigation, Grid, Lazy],
            speed: 500,
            slidesPerView: 1,
            // slidesPerGroupSkip: 1,
            spaceBetween: 0,
            lazy: true,
            navigation: {
                nextEl: ".clients .slider-button_next",
                prevEl: ".clients .slider-button_prev",
            },
            grid: {
                fill: "row",
                rows: 1,
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                    grid: {
                        fill: "row",
                        rows: 2,
                    },
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                    grid: {
                        fill: "row",
                        rows: 3,
                    },
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                    grid: {
                        fill: "row",
                        rows: 4,
                    },
                },
            },
            on: {
                resize: function enableOnlyMobile(swiper) {
                    // Disable the slider when the window width is less than or equal to 768
                    if (950 < window.innerWidth) {
                        swiper.disable();
                        swiper.el.classList.add("-non-slider");
                    }
                    else {
                        swiper.enable();
                        swiper.el.classList.remove("-non-slider");
                    }
                },
            },
        });
        swiper.disable();
        swiper.el.classList.add("-non-slider");
    }
}
